import React from 'react'

const privacypolicy = () => {
    return (
        <>
            <h1 className='text-2xl'>Privacy Policy</h1>
            <br/>
            <div>
            We partner with Microsoft Clarity and Microsoft Advertising to capture how you use and interact with our website through behavioral metrics, heatmaps, and session replay to improve and market our products/services. Website usage data is captured using first and third-party cookies and other tracking technologies to determine the popularity of products/services and online activity. Additionally, we use this information for site optimization, fraud/security purposes, and advertising. For more information about how Microsoft collects and uses your data, visit the&nbsp;<a href="https://privacy.microsoft.com/en-ca/privacystatement">Microsoft Privacy Statement.</a>
            </div>
        </>
    )
}

export default privacypolicy